import { TIME_OPTIONS } from '../helpers/global';
import Storage from '../helpers/Storage';

const FACT_PLACES_KSK = ([
  'на лестн. площадке',
  'в квартире',
  'в тамбуре',
  'в жилом доме',
  'в гараже',
  'в сарае',
  'в подвале',
  'общая кухня',
  'фасад дома',
  'фасад бани',
  'фасад сарая',
  'фасад гаража',
  'фасад здания',
  'ВРУ хозпостройки',
  'ВРУ жилого дома',
  'ВРУ нежилого помещения',
  'ВРУ здания',
  'на опоре ВЛ-0,4 кВ',
  'трубостойка ВЛ-0,4 кВ',
  'РУ-0,4кВ ТП',
  'РЩ-0,4 кВ',
  'нет варианта'
]).map(i => ({id: i, name: i}));

const FACT_PLACES_TNS = ([
  'лестничная площадка',
  'квартира',
  'комната',
  'закрытый тамбур подъезда МЖД',
  'открытый тамбур подъезда МЖД',
  'нет варианта',
]).map(i => ({id: i, name: i}));;

const ZONING_OPTIONS = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
];

export default {
  config: {
    method: "tasks/",
    title: "Задания",
    objectName: "Задание",
    auditableEntity: "Task",
    /*exportItem: {
      title: "Обходной лист",
      method: "tasks/:id/bypass_sheet",
    },*/
    search: {
      group_id: {
        default: "",
        type: "select",
        title: "Группа",
        request: "groups/all",
        showCondition: object => Storage.getData('user')?.group?.admin,
      },
      id: {
        default: "",
        type: "text",
        title: "№",
      },
      account_number: {
        default: "",
        type: "text",
        title: "Л/С",
      },
      user_id: {
        default: null,
        type: "select",
        title: "Инспектор",
        request: "common/inspectors",
        clearable: true,
      },
      area_id: {
        type: "select",
        title: "Район",
        request: "areas/all",
        clearable: true,
      },
      locality_id: {
        type: "select",
        title: "Н. пункт",
        request: "localities/all",
        clearable: true,
      },
      street_id: {
        type: "select",
        title: "Улица",
        request: "streets/all",
        clearable: true,
      },
      house: {
        default: "",
        type: "text",
        title: "Дом",
      },
      building: {
        default: "",
        type: "text",
        title: "Корпус",
      },
      company_id: {
        type: "select",
        title: "Управляющая компания",
        request: "companies/all",
        clearable: true,
      },
      outdated: {
        default: false,
        type: "checkbox",
        title: "Устаревшее",
      },
    },
    sections: [
      {
        title: "Входные данные",
        fields: [
          "id",
          "created_at",
          "updated_at",
          "group_id",
          "account_number",
          "counter_number",
          "counter_type",
          "counter_manufacture_year",
          "counter_place",
          "zoning",
          "capacity",
          "area_id",
          "locality_id",
          "street_id",
          "house",
          "building",
          "apt",
          "postalcode",
          "company_id",
          "consumer_name",
          "last_value_1",
          "last_value_2",
          "last_value_3",
          "bill_email",
          "contacts",
        ],
      },
      {
        title: "Проверка",
        fields: [
          "outdated",
          "user_id",
          "start_at",
          "time_from",
          "time_to",
          "fact_date",
        ]
      },
      {
        title: "Результаты",
        fields: [
          "counter_missing",
          "counter_not_accessible",
          "next_inspection_year_delay",
          "inspection_accordance_result",
          "inaccordance_reason",
          "consumer_instruction",
          "counter_type_match",
          "fact_counter_type",
          "counter_number_match",
          "fact_counter_number",
          "counter_manufacture_year_match",
          "fact_counter_manufacture_year",
          "value_variation",
          "counter_place_match",
          "fact_counter_place",
          "zoning_match",
          "fact_zoning",
          "capacity_match",
          "fact_capacity",
          "accuracy_class",
          "counter_sealed",
          "seal_place",
          "seal_number",
          "counter_not_broken",
          "no_mechanical_damage",
          "no_cracks",
          "tight_glass_fit",
          "free_access_to_switching",
          "indication_presence",
          "current_value_1",
          "current_value_2",
          "current_value_3",
          "comments",
          "fact_bill_email",
          "fact_contacts",
          "photos",
        ]
      },
    ],
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "№",
      preset: true,
      width: "80px",
    },
    created_at: {
      default: "",
      type: "datetime",
      title: "Создан",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "datetime",
      title: "Обновлен",
      preset: true,
    },
    group_id: {
      default: "",
      type: "select",
      title: "Группа",
      request: "groups/all",
      createOnly: true,
      width: "120px",
    },
    group_code: {
      default: "",
      type: "text",
    },
    account_number: {
      default: "",
      type: "text",
      title: "Л/С",
      width: "160px",
    },
    counter_number: {
      default: "",
      type: "text",
      title: "№ ПУ",
    },
    counter_type: {
      default: "",
      type: "text",
      title: "Тип ПУ",
    },
    counter_manufacture_year: {
      default: "",
      type: "text",
      title: "Год изготовления ПУ",
      showCondition: object => object.group_code === 'кск',
    },
    counter_place: {
      default: "",
      type: "text",
      title: "Место установки ПУ",
    },
    zoning: {
      default: null,
      type: "select",
      title: "Зонность",
      options: ZONING_OPTIONS,
    },
    capacity: {
      default: "",
      type: "text",
      title: "Разрядность",
    },
    address: {
      default: "",
      type: "text",
      title: "Адрес",
      showCondition: object => false,
      width: "auto",
    },
    user_object: {
      default: null,
      type: "page-link",
      page: "/users/:id",
      title: "Инспектор",
      showCondition: () => false,
      width: "160px",
    },
    area_id: {
      default: "",
      type: "select",
      title: "Район",
      request: "areas/all"
    },
    locality_id: {
      default: "",
      type: "select",
      title: "Н. пункт",
      request: "localities/all"
    },
    street_id: {
      default: "",
      type: "select",
      title: "Улица",
      request: "streets/all"
    },
    house: {
      default: "",
      type: "text",
      title: "Дом",
    },
    building: {
      default: "",
      type: "text",
      title: "Корпус",
    },
    apt: {
      default: "",
      type: "text",
      title: "Квартира",
    },
    postalcode: {
      default: "",
      type: "text",
      title: "Индекс",
      showCondition: object => object.group_code === 'тнс_инст',
    },
    company_id: {
      default: "",
      type: "select",
      title: "Управляющая компания",
      request: "companies/all",
      showCondition: object => object.group_code === 'кск',
    },
    consumer_name: {
      default: "",
      type: "text",
      title: "ФИО абонента",
      showCondition: object => object.group_code === 'тнс',
    },
    last_value_1: {
      default: "",
      type: "text",
      title: "Предыдущие показания 1",
    },
    last_value_2: {
      default: "",
      type: "text",
      title: "Предыдущие показания 2",
    },
    last_value_3: {
      default: "",
      type: "text",
      title: "Предыдущие показания 3",
    },
    bill_email: {
      default: "",
      type: "text",
      title: "E-mail для квитанций",
      showCondition: object => object.group_code === 'тнс_инст',
    },
    contacts: {
      default: "",
      type: "textarea",
      title: "Контакты",
      showCondition: object => object.group_code === 'тнс_инст',
    },
    user_id: {
      default: "",
      type: "select",
      title: "Инспектор",
      request: "common/inspectors"
    },
    start_at: {
      default: "",
      type: "date",
      title: "Дата проверки",
      clearable: true,
    },
    time_from: {
      default: "",
      type: "select",
      title: "Начальное время",
      options: TIME_OPTIONS,
      clearable: true,
      showCondition: object => !!object.start_at,
    },
    time_to: {
      default: "",
      type: "select",
      title: "Конечное время",
      options: TIME_OPTIONS,
      clearable: true,
      showCondition: object => !!object.start_at,
    },
    fact_date: {
      default: "",
      type: "date",
      title: "Фактическая дата",
    },
    outdated: {
      default: false,
      type: "checkbox",
      title: "Устаревшее",
    },
    counter_missing: {
      default: false,
      type: "checkbox",
      title: "ПУ отсутствует",
    },
    counter_not_accessible: {
      default: false,
      type: "checkbox",
      title: "Не допустили к месту установки ПУ",
    },
    next_inspection_year_delay: {
      default: "",
      type: "text",
      title: "Лет до следующей поверки",
      showCondition: object => object.group_code === 'тнс_инст',
    },
    inspection_accordance_result: {
      default: null,
      type: "select",
      title: "ПУ и пломбы соответствуют требованиям",
      clearable: true,
      options: [
        { id: null, name: '-' },
        { id: false, name: 'Не соответствуют', type: 'danger' },
        { id: true, name: 'Cоответствуют', type: 'success' },
      ],
      showCondition: object => object.group_code === 'тнс_инст',
    },
    inaccordance_reason: {
      default: "",
      type: "textarea",
      title: "Причины несоответствия",
      showCondition: object => object.group_code === 'тнс_инст' && object.inspection_accordance_result === false,
    },
    consumer_instruction: {
      default: "",
      type: "textarea",
      title: "Указания потребителю",
      showCondition: object => object.group_code === 'тнс_инст' && object.inspection_accordance_result === false,
    },
    counter_type_match: {
      default: null,
      type: "select",
      title: "Тип ПУ",
      clearable: true,
      options: [
        { id: null, name: '-' },
        { id: false, name: 'Не соответствует', type: 'danger' },
        { id: true, name: 'Cоответствует', type: 'success' },
      ],
    },
    fact_counter_type: {
      default: "",
      type: "text",
      title: "Фактический тип ПУ",
      showCondition: object => object.group_code === 'тнс' && object.counter_type_match === false,
    },
    counter_number_match: {
      default: null,
      type: "select",
      title: "№ ПУ",
      clearable: true,
      options: [
        { id: null, name: '-' },
        { id: false, name: 'Не соответствует', type: 'danger' },
        { id: true, name: 'Cоответствует', type: 'success' },
      ],
    },
    fact_counter_number: {
      default: "",
      type: "text",
      title: "Фактический № ПУ",
      showCondition: object => object.group_code === 'тнс' && object.counter_number_match === false,
    },
    counter_manufacture_year_match: {
      default: null,
      type: "select",
      title: "Год изготовления ПУ",
      clearable: true,
      options: [
        { id: null, name: '-' },
        { id: false, name: 'Не соответствует', type: 'danger' },
        { id: true, name: 'Cоответствует', type: 'success' },
      ],
      showCondition: object => object.group_code === 'кск',
    },
    fact_counter_manufacture_year: {
      default: "",
      type: "text",
      title: "Фактический год изготовления ПУ",
      showCondition: object => object.group_code === 'тнс_инст' && object.counter_manufacture_year_match === false,
    },
    value_variation: {
      default: "",
      type: "text",
      title: "Величина допустимой погрешности",
      showCondition: object => object.group_code === 'тнс_инст',
    },
    counter_sealed: {
      default: null,
      type: "select",
      title: "Пломба госповерителя",
      clearable: true,
      options: [
        { id: null, name: '-' },
        { id: false, name: 'Отсутствует', type: 'danger' },
        { id: true, name: 'В наличии', type: 'success' },
      ],
    },
    seal_number: {
      default: "",
      type: "text",
      title: "Номер пломбы госповерителя",
      showCondition: object => object.group_code === 'тнс_инст',
    },
    seal_place: {
      default: "",
      type: "select",
      title: "Место установки пломбы",
      clearable: true,
      options: [
        { id: null, name: '-' },
        { id: 'На клемной крышке', name: 'На клемной крышке' },
        { id: 'На корпусе ПУ', name: 'На корпусе ПУ' },
        { id: 'Антимагнитная пломба', name: 'Антимагнитная пломба' },
        { id: 'Прочие места', name: 'Прочие места' },
      ],
      showCondition: object => object.group_code === 'тнс_инст',
    },
    counter_place_match: {
      default: null,
      type: "select",
      title: "Место установки ПУ",
      clearable: true,
      options: [
        { id: null, name: '-' },
        { id: false, name: 'Не соответствует', type: 'danger' },
        { id: true, name: 'Cоответствует', type: 'success' },
      ],
    },
    fact_counter_place: {
      default: "",
      type: "select",
      title: "Фактическое место установки ПУ",
      options: object =>
        object.group_code === 'кск' ? FACT_PLACES_KSK :
        object.group_code === 'тнс' ? FACT_PLACES_TNS :
        [],
      showCondition: object => object.counter_place_match === false,
    },
    zoning_match: {
      default: null,
      type: "select",
      title: "Зонность",
      clearable: true,
      options: [
        { id: null, name: '-' },
        { id: false, name: 'Не соответствует', type: 'danger' },
        { id: true, name: 'Cоответствует', type: 'success' },
      ],
      showCondition: object => object.group_code === 'тнс_инст',
    },
    fact_zoning: {
      default: "",
      type: "select",
      title: "Фактическая зонность",
      options: ZONING_OPTIONS,
      showCondition: object => object.group_code === 'тнс_инст' && object.zoning_match === false,
    },
    capacity_match: {
      default: null,
      type: "select",
      title: "Разрядность",
      clearable: true,
      options: [
        { id: null, name: '-' },
        { id: false, name: 'Не соответствует', type: 'danger' },
        { id: true, name: 'Cоответствует', type: 'success' },
      ],
      showCondition: object => object.group_code === 'тнс_инст',
    },
    fact_capacity: {
      default: "",
      type: "text",
      title: "Фактическая разрядность",
      showCondition: object => object.group_code === 'тнс_инст' && object.capacity_match === false,
    },
    accuracy_class: {
      default: "",
      type: "select",
      title: "Класс точности",
      clearable: true,
      options: [
        { id: null, name: '-' },
        { id: '0,5', name: '0,5' },
        { id: '1,0', name: '1,0' },
        { id: '2,0', name: '2,0' },
        { id: '2,5', name: '2,5' },
      ],
      showCondition: object => object.group_code === 'тнс_инст',
    },
    counter_not_broken: {
      default: null,
      type: "select",
      title: "Повреждения ПУ",
      clearable: true,
      options: [
        { id: null, name: '-' },
        { id: false, name: 'Поврежден', type: 'danger' },
        { id: true, name: 'Не поврежден', type: 'success' },
      ],
    },
    no_mechanical_damage: {
      default: false,
      type: "checkbox",
      title: "Отсутствие механических повреждений",
      showCondition: object => object.group_code === 'тнс_инст',
    },
    no_cracks: {
      default: false,
      type: "checkbox",
      title: "Отсутствие отверстий или трещин",
      showCondition: object => object.group_code === 'тнс_инст',
    },
    tight_glass_fit: {
      default: false,
      type: "checkbox",
      title: "Плотное прилегание стекла индикатора",
      showCondition: object => object.group_code === 'тнс_инст',
    },
    free_access_to_switching: {
      default: false,
      type: "checkbox",
      title: "Свободный доступ к элементам коммутации",
      showCondition: object => object.group_code === 'тнс_инст',
    },
    indication_presence: {
      default: false,
      type: "checkbox",
      title: "Вращение диска, наличие индикации",
      showCondition: object => object.group_code === 'тнс_инст',
    },
    current_value_1: {
      default: "",
      type: "text",
      title: "Текущие показания 1",
    },
    current_value_2: {
      default: "",
      type: "text",
      title: "Текущие показания 2",
    },
    current_value_3: {
      default: "",
      type: "text",
      title: "Текущие показания 3",
    },
    comments: {
      default: "",
      type: "textarea",
      title: "Коментарии",
    },
    fact_bill_email: {
      default: "",
      type: "text",
      title: "E-mail для квитанций",
      showCondition: object => object.group_code === 'тнс_инст',
    },
    fact_contacts: {
      default: "",
      type: "textarea",
      title: "Контакты",
      showCondition: object => object.group_code === 'тнс_инст',
    },
    photo_ids: {
      default: [],
      showCondition: () => false,
    },
    photos: {
      default: [],
      type: "image-list",
      prefixKey: "account_number",
      title: "Фото",
      uploadRequest: "task_photos",
      ids_field: "photo_ids",
    },
  }
};
